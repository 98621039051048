
import { defineComponent } from "vue";
import ApiService from "../services/apiService";
import Loader from "../components/Loader.vue";
import { HomeInfoModel } from "../models/homeInfoModel";
import { ProgramVersionModel } from "../models/programVersionModel";
import { DeviceModel } from "../models/deviceModel";
import { FirmwareModel } from "../models/firmwareModel";
import { InstallerModel } from "../models/installerModel";

export default defineComponent({
  name: "Home",
  data() {
    return {
      loadingProgramsData: false,
      programsData: [] as ProgramVersionModel[],

      loadingDevicesFirmwareData: false,
      devicesFirmwareData: [] as DeviceModel[],

      loadingInstallers: false,
      installersData: [] as InstallerModel[],
    };
  },
  components: {
    Loader,
  },
  async created() {
    this.fetchLastsProgramsVersions();
    this.fetchDevicesFirmwareData();
    this.fetchInstallers();
  },
  methods: {
    async fetchLastsProgramsVersions() {
      try {
        this.loadingProgramsData = true;
        const responseData = await ApiService.instance.getLastProgramsVersions();
        const responseDataWebAPI = await ApiService.instance.getLastWebAPIVersions();
        const responseDataWebRCP = await ApiService.instance.getLastWebRCPVersions();

        if (responseData) {
          this.programsData = responseData;
        }

        if (responseDataWebAPI) {
          this.programsData.push({
            Name: "WebAPI",
            Version: responseDataWebAPI,
          });
        }

        if (responseDataWebRCP) {
          this.programsData.push({
            Name: "WebRCP(SkalfiNext)",
            Version: responseDataWebRCP,
          });
        }
      } catch (err) {
        console.log("ERROR:");
      } finally {
        this.loadingProgramsData = false;
      }
    },
    async fetchDevicesFirmwareData() {
      try {
        this.loadingDevicesFirmwareData = true;

        await ApiService.instance
          .getSK50FirmwareData()
          .then(firmwareVersion => {
            if (firmwareVersion != undefined) {
              this.devicesFirmwareData.push(
                new DeviceModel("Kontroler SK40", [
                  new FirmwareModel(
                    firmwareVersion[0],
                    "http://hsu.skalmex.pl/firmware/download/Firmware_SK40.bin"
                  ),
                ])
              );

              this.devicesFirmwareData.push(
                new DeviceModel("Kontroler SK50", [
                  new FirmwareModel(
                    firmwareVersion[1],
                    "http://hsu.skalmex.pl/firmware/download/Firmware_SK50.bin"
                  ),
                ])
              );
            }
          });

        await ApiService.instance.getSK30FirmwareData().then(res => {
          if (res != undefined) {
            this.devicesFirmwareData.push(
              new DeviceModel("Kontroler SK30", [
                new FirmwareModel(res[0], ""),
                new FirmwareModel(res[1], ""),
              ])
            );
          }
        });

        await ApiService.instance.getSR200FirmwareData().then(res => {
          if (res != undefined) {
            this.devicesFirmwareData.push(
              new DeviceModel("Rejestrator SR200", [new FirmwareModel(res, "")])
            );
          }
        });
      } catch (err) {
        console.log("ERROR:");
      } finally {
        this.loadingDevicesFirmwareData = false;
      }
    },
    async fetchInstallers() {
      try {
        this.loadingInstallers = true;
        const responseData = await ApiService.instance.getInstallers();
        if (responseData) {
          this.installersData = responseData;
        }
      } catch (err) {
        console.log("ERROR:");
      } finally {
        this.loadingInstallers = false;
      }
    },
    downloadFile(filePath) {
      window.location = filePath;
    },
    displayProgramName(obj) {
      let demoLink = "";
      if (obj.DemoLink) {
        demoLink = `(<a href='${obj.DemoLink}'>Pobierz demo</a>)`;
      }

      return `${obj.Name} ${demoLink}`;
    },
  },
});
