<template>
  <div class="categories-container">
    <div
      v-for="category of categories"
      :key="category.name"
      @click="() => itemClick(category)"
      :class="[
        'category-item',
        isActiveCategory(category.name) ? 'active' : 'inactive',
      ]"
    >
      {{ category.name }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import router from "../../router";

export default defineComponent({
  name: "NewSkalfiOptionsMenuCategories",
  props: ["profile", "categories"],
  data() {
    return {};
  },
  methods: {
    itemClick(category) {
      router.push({
        name: "NewSkalfiOptionsContent",
        query: { profileName: this.profile, categoryName: category.name },
        params: {
          options: JSON.stringify(category.options),
        },
      });
    },

    isActiveCategory(categoryName) {
      return (
        this.profile == this.$route.query.profileName &&
        categoryName == this.$route.query.categoryName
      );
    },
  },
});
</script>

<style scoped>
.category-item {
  font: bold 16px Arial;
  cursor: pointer;
  height: 40px;
  border-bottom: 1px solid black;
  padding-left: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
}

.active {
  color: red;
  background-color: #e8e8e8;
}

.inactive {
  color: gray;
}
</style>
