
import { defineComponent } from "vue";
import ApiService from "../services/apiService";
import { ChangelogType } from "../common/changelogType";
import ChangelogViewer from "../components/ChangelogViewer.vue";
import Loader from "../components/Loader.vue";
import { ChangelogItemDataModel } from "../models/changelogItemDataModel";

export default defineComponent({
  name: "Changelog",
  data() {
    return {
      changelogItems: [] as ChangelogItemDataModel[],
      loadingData: false,
    };
  },
  components: {
    ChangelogViewer,
    Loader,
  },
  async created() {
    await this.fetchData(this.$route.params.changelogType as ChangelogType);
  },
  methods: {
    async fetchData(changelogType: ChangelogType) {
      try {
        this.loadingData = true;
        const responseData = await ApiService.instance.getChangelogData(
          changelogType
        );
        if (responseData !== undefined) {
          this.changelogItems = responseData.data;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingData = false;
      }
    },
  },
  computed: {
    changelogHeaderTitle(): string {
      const changelogType: ChangelogType | undefined = this.$route.params
        .changelogType as ChangelogType;

      switch (changelogType) {
        case ChangelogType.RCPAdmin:
          return "Opis zmian - RCPAdmin";
        case ChangelogType.KDAdmin:
          return "Opis zmian - KDAdmin";
        case ChangelogType.SK50:
          return "Opis zmian - Kontroler SK50";
        case ChangelogType.SR200_SR300:
          return "Opis zmian - SR200/SR300";
        case ChangelogType.WebModule:
          return "Opis zmian - Moduł WEB";
        case ChangelogType.SRM100:
          return "Opis zmian - SRM100";
        case ChangelogType.SkalfiMobile:
          return "Opis zmian - Skalfi-mobile";
      }

      return "Opis zmian";
    },
  },
  watch: {
    async "$route.params.changelogType"(changelogType) {
      await this.fetchData(changelogType as ChangelogType);
    },
  },
});
