
import { defineComponent } from "vue";
import ChangelogChangeItem from "./ChangelogChangeItem.vue";
import { ChangelogChangeItemDataModel } from "./../models/changelogChangeItemDataModel";
import { ChangelogChangeType } from "./../common/changelogChangeType";

export default defineComponent({
  name: "ChangelogItem",
  props: {
    itemIndex: Number,
    version: String,
    date: String,
    changes: Array as () => Array<ChangelogChangeItemDataModel>,
    displayFeatures: Boolean,
    displayImprovments: Boolean,
    displayBugFixes: Boolean,
  },
  data() {
    return {};
  },
  components: {
    ChangelogChangeItem,
  },
  computed: {
    filteredChanges(): Array<ChangelogChangeItemDataModel> | undefined {
      return this.changes?.filter(v => {
        if (
          (this.displayFeatures && v.Type == ChangelogChangeType.Feature) ||
          (this.displayImprovments &&
            v.Type == ChangelogChangeType.Improvment) ||
          (this.displayBugFixes && v.Type == ChangelogChangeType.Fix)
        ) {
          return true;
        } else {
          return false;
        }
      });
    },
  },
  methods: {
    scrollToDiv(e: Event, divId: string) {
      e.preventDefault();

      const divElement = document.getElementById(divId);
      if (divElement) {
        divElement.scrollIntoView({ behavior: "smooth" });
      }

      return true;
    },
  },
});
