
import { defineComponent, reactive } from "vue";
import ApiService from "../services/apiService";
import { InstructionType } from "../common/instructionType";
import { TreeMenuNodeModel } from "../models/treeMenuNodeModel";
import TreeMenu from "../components/TreeMenu.vue";
import Loader from "../components/Loader.vue";
import { GetPdfInstructionPath } from "../common/instructions";

import $ from "jquery";

export default defineComponent({
  name: "SoftwareInstruction",
  data() {
    return {
      loadingData: false,
      displayImagesLightBox: false,
      images: [] as string[],
      imageIndex: 0,
      pdfFilePath: "",
      instructionContent: "",
      goToTopButtonElement: null as any,
      tocElement: null as any,

      tocData: {
        id: "",
        label: "root",
        nodes: [],
      },
    };
  },
  components: {
    Loader,
    TreeMenu,
  },
  mounted() {
    this.goToTopButtonElement = document.getElementById("goToTopButton")!;
    this.tocElement = document.getElementById("instruction_sidebar");

    this.updateUIForMediaQuery();
  },
  async created() {
    window.addEventListener("resize", this.resizeWindowListener);

    $(() => {
      const val: any = $('[data-toggle="tooltip"]');
      val.tooltip({
        animated: "fade",
        placement: "bottom",
        html: true,
      });

      $(".maparea").on("mousemove", function() {
        const coords = $(this).attr("coords");
        let pos: Array<string> = [];

        if (coords != undefined) {
          pos = coords.split(",");

          $(".tooltip")
            .css({ left: parseInt(pos[2]) + 5, top: parseInt(pos[1]) + 35 })
            .fadeIn("slow");
        }
      });
    });

    window.addEventListener("scroll", this.scrollPageListener);

    await this.fetchData(this.$route.params.instructionType as InstructionType);

    const rootFaqElement = document.getElementById(
      "software_instruction_content"
    );
    if (rootFaqElement != null) {
      const imagesElements = rootFaqElement.getElementsByTagName("img");

      for (let i = 0; i < imagesElements.length; i++) {
        this.images.push(imagesElements[i].src);
        imagesElements[i].onclick = () => {
          this.displayImagesLightBox = true;
          this.imageIndex = i;
        };
      }
    }
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrollPageListener);
  },
  methods: {
    prepareDownloadLinks() {
      const pdfInstructionPath = GetPdfInstructionPath(
        this.$route.params.instructionType as InstructionType
      );

      const downloadPDFElem = document.getElementById(
        "software_instruction_download_pdf"
      )!;

      downloadPDFElem.setAttribute("href", pdfInstructionPath);
    },
    prepareTOC(
      parentHTMLNode: (Node & ParentNode) | null,
      rootElement: TreeMenuNodeModel,
      headerSize = 1
    ): void {
      if (headerSize >= 7) return;
      if (parentHTMLNode == null) return;

      const elements = parentHTMLNode.querySelectorAll(
        `section h${headerSize}`
      );

      const time = Date.now();
      const randomNum = Math.floor(Math.random() * 100);
      for (let i = 0; i < elements.length; i++) {
        const tempObj: TreeMenuNodeModel = {
          id: "header_" + (time + i + 1) + "_" + randomNum,
          label: elements[i].innerHTML,
          nodes: [],
        };

        rootElement.nodes.push(tempObj);
        this.prepareTOC(elements[i].parentNode, tempObj, headerSize + 1);

        elements[i].id = tempObj.id;
      }
    },


    async fetchData(instructionType: InstructionType) {
      try {
        this.loadingData = true;
        const responseData = await ApiService.instance.getInstructionData(
          instructionType
        );

        this.instructionContent = responseData ? responseData.data : "";
        setTimeout(() => {
          this.prepareDownloadLinks(),
            this.prepareTOC(document, this.tocData, 1);
        }, 100);
      } catch (err) {
        console.log("ERROR: " + err);
      } finally {
        this.loadingData = false;
      }
    },
    showImagesLightBox() {
      this.displayImagesLightBox = true;
    },
    hideImagesLightBox() {
      this.displayImagesLightBox = false;
    },
    goToTopButtonClick() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    scrollPageListener() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.goToTopButtonElement.style.display = "block";
      } else {
        this.goToTopButtonElement.style.display = "none";
      }

      if (this.tocElement) {
        const sticky = Math.ceil(this.tocElement.offsetTop);
        const pageYOffset = Math.ceil(window.pageYOffset);

        if (pageYOffset >= sticky) {
          this.tocElement.classList.add("sticky");
        } else {
          this.tocElement.classList.remove("sticky");
        }
      }
    },
    menuItemClicked(id: string) {
      ($("#tocModal") as any).modal("hide");

      const elem = document.getElementById(id);
      if (elem) {
        const yOffset = -60;
        const y =
          elem.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y });
        //elem.scrollIntoView(true);
      }
    },
    resizeWindowListener() {
      this.updateUIForMediaQuery();
    },
    updateUIForMediaQuery() {
      const matchMediaQuery = window.matchMedia(" (max-width: 767px) ");

      if (matchMediaQuery.matches) {
        this.tocElement.style.display = "none";
      } else {
        this.tocElement.style.display = "block";

        ($("#tocModal") as any).modal("hide");
      }
    },
  },
  computed: {},
});
