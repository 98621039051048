import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "text-center mt-3 mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")
  const _component_ChangelogViewer = _resolveComponent("ChangelogViewer")

  return (_openBlock(), _createBlock("div", null, [
    _createVNode("h1", _hoisted_1, _toDisplayString(_ctx.changelogHeaderTitle), 1),
    (_ctx.loadingData)
      ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
      : (_openBlock(), _createBlock(_component_ChangelogViewer, {
          key: 1,
          items: _ctx.changelogItems
        }, null, 8, ["items"]))
  ]))
}