import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Instructions from "../views/Instructions.vue";
import NewSkalfiOptions from "../views/NewSkalfiOptions.vue";
import WebAPI from "../views/WebAPI.vue";
import TechnicalSheets from "../views/TechnicalSheets.vue";
import Certificates from "../views/Certificates.vue";
import Changelog from "../views/Changelog.vue";
import Contact from "../views/Contact.vue";
import Faq from "../views/Faq.vue";
import NewSkalfiOptionsContent from "../components/NewSkalfiOptions/NewSkalfiOptionsContent.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/instructions/web_rcp_configuration",
    name: "NewSkalfiOptions",
    component: NewSkalfiOptions,
    children: [
      {
        path: ":profileName?/:categoryName?",
        name: "NewSkalfiOptionsContent",
        component: NewSkalfiOptionsContent,
        props: true,
      },
    ],
  },
  {
    path: "/instructions/:instructionType",
    name: "SoftwareInstructions",
    component: Instructions,
  },
  {
    path: "/webapi/:instructionType",
    name: "WebAPI",
    component: WebAPI,
  },
  {
    path: "/technical_sheets",
    name: "TechnicalSheets",
    component: TechnicalSheets,
  },
  {
    path: "/certificates",
    name: "Certificates",
    component: Certificates,
  },
  {
    path: "/faq/:faqType",
    name: "Faq",
    component: Faq,
  },
  {
    path: "/changelog/:changelogType",
    name: "Changelog",
    component: Changelog,
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
