<template>
  <div class="tree-menu">
    <div class="label-wrapper" :style="indent" v-if="depth >= 0">
      <div
        @click="toggleChildren"
        :class="labelClasses"
        style="font-size: 20px"
      >
        <font-awesome-icon
          :icon="['fas', 'angle-right']"
          v-if="!showChildren"
        />

        <font-awesome-icon :icon="['fas', 'angle-down']" v-if="showChildren" />
      </div>
      &nbsp;&nbsp;&nbsp;
      <a href="#" @click="itemClick" style="display: block">{{ label }}</a>
    </div>

    <template v-if="showChildren || depth == -1">
      <TreeMenu
        v-for="node in nodes"
        :nodes="node.nodes"
        :id="node.id"
        :label="node.label"
        :depth="depth + 1"
        :key="node"
        @itemClicked="onItemClicked"
      ></TreeMenu>
    </template>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import $ from "jquery";

export default defineComponent({
  name: "TreeMenu",
  props: ["nodes", "label", "depth", "id"],
  data() {
    return {
      showChildren: false,
    };
  },
  computed: {
    iconClasses() {
      return {
        "fa-angle-right": !this.showChildren,
        "fa-angle-down": this.showChildren,
      };
    },
    labelClasses() {
      return { "has-children": this.nodes.length > 0 };
    },
    indent() {
      return {
        "margin-left": `${this.depth * 20}px`,
        display: "flex",
      };
    },
  },
  methods: {
    onItemClicked(id) {
      this.$emit("itemClicked", id);
    },
    toggleChildren() {
      if (this.nodes.length > 0) {
        this.showChildren = !this.showChildren;
      }
    },
    itemClick(e) {
      e.preventDefault();
      this.$emit("itemClicked", this.id);
      const elem = document.getElementById(this.id);
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
});
</script>

<style scoped>
.tree-menu .label-wrapper {
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;

  display: flex;
  align-items: center;
}

.has-children {
  cursor: pointer;
}
</style>
