/* eslint-disable @typescript-eslint/camelcase */

export enum InstructionType {
  /*Programs instructions*/
  RCPAdmin = "rcpadmin",
  KDAdmin = "kdadmin",
  KDPortier = "kdportier",
  WebRCP = "webrcp",
  RCPMini = "rcp_mini",
  EmployeeProfile = "employee_profile",
  SuperiorProfile = "superior_profile",
  InstallationRecoveryTransfer = "installation_recovery_transfer",
  AndroidOpendoor = "android_opendoor",
  Card2Key = "card2key",
  ZleceniaProjektyOperacje = "zlecenia_projekty_operacje",
  GUARD_MODULE = "guard_module",
  Skalfi_mobile = "skalfi_mobile",
  Skalfi_mobile_superior_time = "skalfi_mobile_superior_time",
  SkalfiSetup = "skalfi_setup",
  WebAPIBasicInfo = "webapi_basic_info",
  IntegrationWithST5Readers = "integration_with_st5_readers",
  Skalmex_support = "skalmex_support",
  SkalfiWeb = "skalfi_web",
  WebRCPConfiguration = "web_rcp_configuration",
  Skalmex_support_card = "skalmex_support_card",

  /*Devices instructions*/
  SKD30 = "skd30",
  SKD50 = "skd50",
  SP35 = "sp35",
  SC210Mv2Connecting = "sc210-mv2_connecting",
  SCU100Installation = "scu100_installation",
  SCU115Connecting = "scu115_connecting",
  SCU120_R_Connecting = "scu120-r_connecting",
  SCU140Installation = "scu140-installation",
  SCU200Installation = "scu200-installation",
  SCU210_MV3 = "scu210-mv3_connecting",
  SCU240Installation = "scu240_installation",
  SK10Connecting = "sk10_connecting",
  SK20Installation = "sk20_installation",
  SK26Installation = "sk26_installation",
  SR110Ev2_SBR110E = "sr110-ev2_SBR110e_connecting_button",
  INFOMAT = "infomat",
  SRM100 = "srm100",
  SK50 = "sk50",
  SK50_OSDP = "sk50_osdp",
  SK50_OSDP_SCU200 = "sk50_osdp_scu200",
  SK50_OSDP_HID = "sk50_osdp_hid",
  SR200 = "sr200",
  BuzzerLampToSR100SR110 = "buzzer_lamp_to_sr100_sr110",
  SigmaLiteReaderConfiguration = "sigma_lite_reader_configuration",
}
