
import { defineComponent } from "vue";
import $ from "jquery";

export default defineComponent({
  name: "FaqItem",
  props: {
    itemIndex: Number,
    title: String,
    content: String,
    dataParentName: String,
  },
  data() {
    return {
      iconIndex: 0,
    };
  },
  created() {
    $(() => {
      $("#collapse_" + this.itemIndex).on("shown.bs.collapse", () => {
        this.iconIndex = 1;
      });

      $("#collapse_" + this.itemIndex).on("hidden.bs.collapse", () => {
        this.iconIndex = 0;
      });

      $("#icon_" + this.itemIndex).click(() => {
        if (this.iconIndex == 0) {
          ($(`#collapse_${this.itemIndex}`) as any).collapse("show");
        } else {
          ($(`#collapse_${this.itemIndex}`) as any).collapse("hide");
        }
      });
    });
  },
});
