
import { defineComponent } from "vue";
import ApiService from "../services/apiService";
import { FaqType } from "../common/faqType";
import FaqItem from "../components/FaqItem.vue";
import Loader from "../components/Loader.vue";
import { FaqItemDataModel } from "../models/faqItemDataModel";

export default defineComponent({
  name: "Faq",
  data() {
    return {
      faqItems: [] as FaqItemDataModel[],
      loadingData: false,
      displayImagesLightBox: false,
      images: [] as string[],
      imageIndex: 0,
    };
  },
  components: {
    FaqItem,
    Loader,
  },
  async created() {
    await this.fetchData(this.$route.params.faqType as FaqType);

    const rootFaqElement = document.getElementById("faq_accordion");
    if (rootFaqElement != null) {
      const imagesElements = rootFaqElement.getElementsByTagName("img");

      for (let i = 0; i < imagesElements.length; i++) {
        this.images.push(imagesElements[i].src);
        imagesElements[i].onclick = () => {
          this.displayImagesLightBox = true;
          this.imageIndex = i;
        };
      }
    }
  },
  methods: {
    async fetchData(faqType: FaqType) {
      try {
        this.loadingData = true;
        const responseData = await ApiService.instance.getFaqData(faqType);
        if (responseData !== undefined) {
          this.faqItems = responseData.data;
        }
      } catch (err) {
        console.log("ERROR: " + err);
      } finally {
        this.loadingData = false;
      }
    },
    showImagesLightBox() {
      this.displayImagesLightBox = true;
    },
    hideImagesLightBox() {
      this.displayImagesLightBox = false;
    },
  },
  computed: {
    faqHeaderTitle(): string {
      const faqType: FaqType | undefined = this.$route.params
        .faqType as FaqType;

      switch (faqType) {
        case FaqType.RCPAdmin:
          return "FAQ - RCPAdmin";
        case FaqType.KDAdmin:
          return "FAQ - KDAdmin";
        case FaqType.AccessControl:
          return "FAQ - Moduł Web";
        case FaqType.WebModule:
          return "FAQ - Moduł Web";
        case FaqType.RODO:
          return "FAQ - RODO";
        case FaqType.MiniStandardPremiumElite:
          return "FAQ - Mini Standard Premium Elite";
        case FaqType.Usage:
          return "FAQ - Użytkowanie";
      }
      /*if (faqType !== undefined)
        return FaqType[]*/

      return "Faq";
    },
  },
  watch: {
    async "$route.params.faqType"(faqType) {
      await this.fetchData(faqType as FaqType);
    },
  },
});
