import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "text-center mt-4" }
const _hoisted_3 = {
  class: "accordion",
  id: "faq_accordion"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loader = _resolveComponent("Loader")
  const _component_FaqItem = _resolveComponent("FaqItem")
  const _component_vue_easy_lightbox = _resolveComponent("vue-easy-lightbox")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("h1", _hoisted_2, _toDisplayString(_ctx.faqHeaderTitle), 1),
    _createVNode("div", _hoisted_3, [
      (_ctx.loadingData)
        ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
        : (_openBlock(true), _createBlock(_Fragment, { key: 1 }, _renderList(_ctx.faqItems, (item, index) => {
            return (_openBlock(), _createBlock(_component_FaqItem, {
              key: item,
              itemIndex: index,
              title: item.Title,
              content: item.Content,
              dataParentName: "#faq_accordion"
            }, null, 8, ["itemIndex", "title", "content"]))
          }), 128)),
      _createVNode(_component_vue_easy_lightbox, {
        escDisabled: "",
        moveDisabled: "",
        visible: _ctx.displayImagesLightBox,
        imgs: _ctx.images,
        index: _ctx.imageIndex,
        onHide: _ctx.hideImagesLightBox
      }, null, 8, ["visible", "imgs", "index", "onHide"])
    ])
  ]))
}