import axios, {
  AxiosInstance,
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { FaqType } from "../common/faqType";
import { ChangelogType } from "../common/changelogType";
import { FaqItemDataModel } from "../models/faqItemDataModel";
import { HomeInfoModel } from "../models/homeInfoModel";
import { ChangelogItemDataModel } from "../models/changelogItemDataModel";
import { InstructionType } from "../common/instructionType";
import { CertificateModel } from "../models/certificateModel";
import { TechnicalSheetModel } from "../models/technicalSheetModel";
import { ProgramVersionModel } from "../models/programVersionModel";
import { FirmwareModel } from "../models/firmwareModel";
import { InstallerModel } from "../models/installerModel";

export default class ApiService {
  public axios: AxiosInstance;
  public axiosHSU: AxiosInstance;
  public axiosHOME: AxiosInstance;
  private static _instance: ApiService = new ApiService();

  private constructor() {
    this.axios = axios.create({
      baseURL: process.env.VUE_APP_BASE_URL,
      timeout: 30 * 1000,
    });

    this.axiosHSU = axios.create({
      baseURL: "https://hsu.skalmex.pl/",
      timeout: 30 * 1000,
    });

    this.axiosHOME = axios.create({
      baseURL: "https://skalmex.com.pl/",
      timeout: 30 * 1000,
    });

    this.axios.interceptors.response.use(
      this.ResponseFulfilled,
      this.ResponseRejected
    );
    this.axios.interceptors.request.use(
      this.RequestFulfilled,
      this.RequestRejected
    );

    this.axios.defaults.headers.get["Content-Type"] = "application/json";
    this.axios.defaults.headers.post["Content-Type"] = "application/json";
    this.axios.defaults.headers.get["Accept"] = "application/json";

    this.axiosHSU.defaults.headers.get["Content-Type"] = "application/json";
    this.axiosHSU.defaults.headers.get["Accept"] = "application/json";

    this.axiosHOME.defaults.headers.get["Content-Type"] = "application/json";
    this.axiosHOME.defaults.headers.get["Accept"] = "application/json";
  }

  static get instance(): ApiService {
    return ApiService._instance;
  }

  private RequestFulfilled(
    config: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> {
    return Promise.resolve(config);
  }

  private RequestRejected(config: AxiosError): Promise<AxiosRequestConfig> {
    return Promise.reject(config);
  }

  private ResponseFulfilled(response: any): Promise<AxiosResponse> {
    return Promise.resolve(response);
  }

  private ResponseRejected(error: AxiosError): Promise<AxiosResponse> {
    return Promise.reject(error);
  }

  public async getTechnicalSheetsData(): Promise<
    TechnicalSheetModel[] | undefined
  > {
    return this.axios
      .get("/content/technical_sheets/technical_sheets.json")
      .then(v => v.data.TechnicalSheets);
  }

  public async getCertificatesData(): Promise<CertificateModel[] | undefined> {
    return this.axios
      .get("/content/certificates/certificates.json")
      .then(v => v.data.Certificates);
  }

  public async getLastProgramsVersions(): Promise<
    ProgramVersionModel[] | undefined
  > {
    return this.axiosHSU
      .get("/firmware/UpdaterNext_WersjeOprogramowania.json")
      .then(v => v.data.Programs);
  }

  public async getLastWebAPIVersions(): Promise<string | undefined> {
    return this.axiosHOME.get("/webapi_version").then(v => v.data);
  }

  public async getLastWebRCPVersions(): Promise<string | undefined> {
    return this.axiosHOME.get("/webrcp_version").then(v => v.data);
  }

  public async getSK30FirmwareData(): Promise<string[] | undefined> {
    return this.axiosHSU
      .get("/firmware/SK30FirmwareVersion.json")
      .then(v => [v.data.KDVersion, v.data.KOMVersion]);
  }

  public async getSK50FirmwareData(): Promise<string[] | undefined> {
    return this.axiosHSU
      .get("/firmware/SK40_SK50_FirmwareVersion.json")
      .then(v => [v.data.firmwareVersionSK40, v.data.firmwareVersionSK50]);
  }

  public async getSR200FirmwareData(): Promise<string | undefined> {
    return this.axiosHSU
      .get("/firmware/SR200FirmwareVersion.json")
      .then(v => v.data.firmwareVersion);
  }

  public async getInstallers(): Promise<InstallerModel[] | undefined> {
    return this.axios
      .get("/content/home/installers.json")
      .then(v => v.data.Installers);
  }

  public async getNewSkalfiOptionsData() {
    return this.axios.get<string>(
      "/content/instructions/software/new_skalfi_options.json"
    );
  }

  public async getInstructionData(
    instructionType: InstructionType
  ): Promise<AxiosResponse<string> | undefined> {
    switch (instructionType) {
      case InstructionType.RCPAdmin:
        return this.axios.get<string>(
          "/content/instructions/software/rcpadmin.html"
        );

      case InstructionType.KDAdmin:
        return this.axios.get<string>(
          "/content/instructions/software/kdadmin.html"
        );

      case InstructionType.KDPortier:
        return this.axios.get<string>(
          "/content/instructions/software/kdportier.html"
        );

      case InstructionType.WebRCP:
        return this.axios.get<string>(
          "/content/instructions/software/webrcp.html"
        );

      case InstructionType.RCPMini:
        return this.axios.get<string>(
          "/content/instructions/software/rcp_mini.html"
        );

      case InstructionType.EmployeeProfile:
        return this.axios.get<string>(
          "/content/instructions/software/employee_profile.html"
        );

      case InstructionType.SuperiorProfile:
        return this.axios.get<string>(
          "/content/instructions/software/superior_profile.html"
        );

      case InstructionType.InstallationRecoveryTransfer:
        return this.axios.get<string>(
          "/content/instructions/software/installation_recovery_transfer.html"
        );

      case InstructionType.AndroidOpendoor:
        return this.axios.get<string>(
          "/content/instructions/software/android_opendoor.html"
        );

      case InstructionType.Card2Key:
        return this.axios.get<string>(
          "/content/instructions/software/card2key.html"
        );

      case InstructionType.ZleceniaProjektyOperacje:
        return this.axios.get<string>(
          "/content/instructions/software/zlecenia_projekty_operacje.html"
        );

      case InstructionType.Skalfi_mobile:
        return this.axios.get<string>(
          "/content/instructions/software/skalfi_mobile.html"
        );
      case InstructionType.Skalfi_mobile_superior_time:
        return this.axios.get<string>(
          "/content/instructions/software/skalfi_mobile_superior_time.html"
        );
      case InstructionType.SkalfiSetup:
        return this.axios.get<string>(
          "/content/instructions/software/skalfi_setup.html"
        );

      case InstructionType.SP35:
        return this.axios.get<string>(
          "/content/instructions/devices/sp35.html"
        );

      case InstructionType.SKD30:
        return this.axios.get<string>(
          "/content/instructions/devices/skd30.html"
        );

      case InstructionType.SKD50:
        return this.axios.get<string>(
          "/content/instructions/devices/skd50.html"
        );

      case InstructionType.SC210Mv2Connecting:
        return this.axios.get<string>(
          "/content/instructions/devices/sc210-mv2_connecting.html"
        );

      case InstructionType.SCU100Installation:
        return this.axios.get<string>(
          "/content/instructions/devices/scu100_installation.html"
        );

      case InstructionType.SCU115Connecting:
        return this.axios.get<string>(
          "/content/instructions/devices/scu115_connecting.html"
        );

      case InstructionType.SCU120_R_Connecting:
        return this.axios.get<string>(
          "/content/instructions/devices/scu120-r_connecting.html"
        );

      case InstructionType.SCU140Installation:
        return this.axios.get<string>(
          "/content/instructions/devices/scu140-installation.html"
        );

      case InstructionType.SCU200Installation:
        return this.axios.get<string>(
          "/content/instructions/devices/scu200-installation.html"
        );

      case InstructionType.SCU210_MV3:
        return this.axios.get<string>(
          "/content/instructions/devices/scu210-mv3_connecting.html"
        );

      case InstructionType.SCU240Installation:
        return this.axios.get<string>(
          "/content/instructions/devices/scu240_installation.html"
        );
      case InstructionType.SK10Connecting:
        return this.axios.get<string>(
          "/content/instructions/devices/sk10_connecting.html"
        );

      case InstructionType.SK20Installation:
        return this.axios.get<string>(
          "/content/instructions/devices/sk20_installation.html"
        );

      case InstructionType.SK26Installation:
        return this.axios.get<string>(
          "/content/instructions/devices/sk26_installation.html"
        );

      case InstructionType.SR110Ev2_SBR110E:
        return this.axios.get<string>(
          "/content/instructions/devices/sr110-ev2_SBR110e_connecting_button.html"
        );

      case InstructionType.INFOMAT:
        return this.axios.get<string>(
          "/content/instructions/devices/infomat.html"
        );

      case InstructionType.SRM100:
        return this.axios.get<string>(
          "/content/instructions/devices/srm100.html"
        );

      case InstructionType.GUARD_MODULE:
        return this.axios.get<string>(
          "/content/instructions/software/guard_module.html"
        );

      case InstructionType.SK50:
        return this.axios.get<string>(
          "/content/instructions/devices/sk50.html"
        );

      case InstructionType.SK50_OSDP:
        return this.axios.get<string>(
          "/content/instructions/devices/sk50_osdp.html"
        );

      case InstructionType.SK50_OSDP_SCU200:
        return this.axios.get<string>(
          "/content/instructions/devices/sk50_osdp_scu200.html"
        );

      case InstructionType.SK50_OSDP_HID:
        return this.axios.get<string>(
          "/content/instructions/devices/sk50_osdp_hid.html"
        );

      case InstructionType.SR200:
        return this.axios.get<string>(
          "/content/instructions/devices/sr200.html"
        );

      case InstructionType.WebAPIBasicInfo:
        return this.axios.get<string>("/content/webapi/webapi_basic_info.html");

      case InstructionType.IntegrationWithST5Readers:
        return this.axios.get<string>(
          "/content/webapi/integration_with_st5_readers.html"
        );

      case InstructionType.BuzzerLampToSR100SR110:
        return this.axios.get<string>(
          "/content/instructions/devices/buzzer_lamp_to_sr100_sr110.html"
        );
      case InstructionType.Skalmex_support:
        return this.axios.get<string>(
          "/content/instructions/software/skalmex_support.html"
        );
      case InstructionType.SkalfiWeb:
        return this.axios.get<string>(
          "/content/instructions/software/skalfi_web.html"
        );
      case InstructionType.WebRCPConfiguration:
        return this.axios.get<string>(
          "/content/instructions/software/web_rcp_configuration.html"
        );
      case InstructionType.Skalmex_support_card:
        return this.axios.get<string>(
          "/content/instructions/software/skalmex_support_card.html"
        );

      case InstructionType.SigmaLiteReaderConfiguration:
        return this.axios.get<string>(
          "/content/instructions/devices/sigma_lite_reader_configuration.html"
        );
    }
  }

  public async getFaqData(
    faqType: FaqType
  ): Promise<AxiosResponse<FaqItemDataModel[]> | undefined> {
    switch (faqType) {
      case FaqType.RCPAdmin:
        return this.axios.get<FaqItemDataModel[]>(
          "/content/faq/rcpadmin_faq.json"
        );
      case FaqType.KDAdmin:
        return this.axios.get<FaqItemDataModel[]>(
          "/content/faq/kdadmin_faq.json"
        );
      case FaqType.AccessControl:
        return this.axios.get<FaqItemDataModel[]>(
          "/content/faq/access_control_faq.json"
        );
      case FaqType.WebModule:
        return this.axios.get<FaqItemDataModel[]>(
          "/content/faq/web_module_faq.json"
        );
      case FaqType.RODO:
        return this.axios.get<FaqItemDataModel[]>("/content/faq/rodo_faq.json");
      case FaqType.EXPORT_IMPORT:
        return this.axios.get<FaqItemDataModel[]>(
          "/content/faq/export_import_faq.json"
        );
      case FaqType.MiniStandardPremiumElite:
        return this.axios.get<FaqItemDataModel[]>(
          "/content/faq/mini_standard_premium_elite_faq.json"
        );
      case FaqType.Usage:
        return this.axios.get<FaqItemDataModel[]>(
          "/content/faq/usage_faq.json"
        );
    }
  }

  public async getChangelogData(
    changelogType: ChangelogType
  ): Promise<AxiosResponse<ChangelogItemDataModel[]> | undefined> {
    switch (changelogType) {
      case ChangelogType.RCPAdmin:
        return this.axios.get<ChangelogItemDataModel[]>(
          "/content/changelog/rcpadmin_changelog.json"
        );
      case ChangelogType.KDAdmin:
        return this.axios.get<ChangelogItemDataModel[]>(
          "/content/changelog/kdadmin_changelog.json"
        );
      case ChangelogType.SK50:
        return this.axios.get<ChangelogItemDataModel[]>(
          "/content/changelog/sk50_changelog.json"
        );
      case ChangelogType.SR200_SR300:
        return this.axios.get<ChangelogItemDataModel[]>(
          "/content/changelog/sr200_sr300_changelog.json"
        );

      case ChangelogType.WebModule:
        return this.axios.get<ChangelogItemDataModel[]>(
          "/content/changelog/web_module_changelog.json"
        );

      case ChangelogType.NewWebModule:
        return this.axios.get<ChangelogItemDataModel[]>(
          "/content/changelog/new_web_module_changelog.json"
        );

      case ChangelogType.WebAPI:
        return this.axios.get<ChangelogItemDataModel[]>(
          "/content/changelog/webapi_changelog.json"
        );

      case ChangelogType.SRM100:
        return this.axios.get<ChangelogItemDataModel[]>(
          "/content/changelog/srm100_changelog.json"
        );

      case ChangelogType.SkalfiMobile:
        return this.axios.get<ChangelogItemDataModel[]>(
          "/content/changelog/skalfi_mobile_changelog.json"
        );

      case ChangelogType.Infomat:
        return this.axios.get<ChangelogItemDataModel[]>(
          "/content/changelog/infomat_changelog.json"
        );
    }
  }
}
