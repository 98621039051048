<template>
  <div class="container">
    <div class="row contact-row">
      <div class="col-md-4 text-center contact-col">
        <img src="../assets/contact/contact_home.png" />
        <h2>
          Adres
        </h2>
        <p>
          Skalmex sp. z o.o.<br />
          ul. Boczkowska 7<br />
          Skalmierzyce<br />
          63-460 Nowe Skalmierzyce
        </p>
      </div>

      <div class="col-md-4 text-center contact-col">
        <img class="center" src="../assets/contact/contact_tel.png" />
        <h2>
          Telefon
        </h2>
        <p>
          Sekretariat Skalmierzyce:<br />
          +48 62 762 09 10<br />
          +48 62 762 10 39
        </p>
      </div>

      <div class="col-md-4 text-center contact-col">
        <img src="../assets/contact/contact_mail.png" />
        <h2>
          Email
        </h2>
        <p>
          <a href="mailto:sekretariat@skalmex.com.pl"
            >sekretariat@skalmex.com.pl</a
          ><br />
          <a href="mailto:skalmex@skalmex.com.pl">skalmex@skalmex.com.pl</a>
        </p>
      </div>
    </div>

    <div class="row contact-row">
      <div class="col-md-4 text-center contact-col">
        <img
          src="../assets/contact/ico_contact_sekretariat_wroclaw.png"
          class="icon"
        />
        <h2>
          Biuro Wrocław
        </h2>
        <p>
          ul. Belgijska 56<br />
          54-404 Wrocław
        </p>
      </div>
      <div class="col-md-4 text-center contact-col">
        <img src="../assets/contact/ico_contact_finanse.png" class="icon" />
        <h2>
          Dział Finansowy
        </h2>
        <p>
          +48 62 762 09 10 wew. 950<br />
          +48 693 363 015
        </p>
      </div>
      <div class="col-md-4 text-center contact-col">
        <img
          src="../assets/contact/ico_contact_zaopatrzenie.png"
          class="icon"
        />
        <h2>
          Zaopatrzenie
        </h2>
        <p>
          +48 62 762 09 10 wew. 946<br />
          +48 693 363 090
        </p>
      </div>
    </div>

    <div class="row contact-row">
      <div class="col-md-4 text-center contact-col">
        <img src="../assets/contact/ico_contact_logistyka.png" class="icon" />
        <h2>
          Logistyka
        </h2>
        <p>
          +48 62 762 09 10 wew. 938<br />
          +48 693 363 454
        </p>
      </div>
      <div class="col-md-4 text-center contact-col">
        <img
          src="../assets/contact/ico_contact_handel_systemy.png"
          class="icon"
        />
        <h2>
          Dział Handlowy<br />
          Zakładu Systemów
        </h2>
        <p>
          +48 62 762 09 10 wew. 930<br />
          +48 693 363 484
        </p>
      </div>
      <div class="col-md-4 text-center contact-col">
        <img
          src="../assets/contact/ico_contact_handel_elektronika.png"
          class="icon"
        />
        <h2>
          Dział Handlowy<br />
          Zakładu Elektroniki
        </h2>
        <p>
          +48 62 762 09 10 wew. 948<br />
          +48 693 363 050
        </p>
      </div>
    </div>

    <div class="row contact-row">
      <div class="col-md-12 text-center contact-col">
        <img src="../assets/contact/ico_contact_produkcja.png" class="icon" />
        <h2>
          Produkcja
        </h2>
        <p>
          +48 62 762 09 10 wew. 929
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.contact-row {
  padding-top: 50px;
}

.contact-col {
  padding-top: 10px;
}

.icon {
  max-width: 85px;
}

a {
  font-size: 14px;
  line-height: 1.42857143;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  text-align: Center;
  box-sizing: border-box;
  background-color: transparent;
  text-decoration: none;
  color: #767676;
}
</style>
