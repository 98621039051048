export enum ChangelogType {
  RCPAdmin = "rcpadmin",
  KDAdmin = "kdadmin",
  SK50 = "sk50",
  SR200_SR300 = "sr200_sr300",
  WebModule = "web_module",
  NewWebModule = "new_web_module",
  WebAPI = "webapi",
  SRM100 = "SRM100",
  SkalfiMobile = "Skalfi-mobile",
  Infomat = "Infomat",
}
