
import { defineComponent } from "vue";
import ChangelogItem from "./ChangelogItem.vue";
import { ChangelogItemDataModel } from "./../models/changelogItemDataModel";
//import $ from "jquery";

export default defineComponent({
  name: "ChangelogViewer",
  props: {
    items: Array as () => Array<ChangelogItemDataModel>,
  },
  data() {
    return {
      checkedFilters: [],
      changelogGoToVersionHovered: false,
      displayFeatures: true,
      displayImprovments: true,
      displayBugFixes: true,
      displayImagesLightBox: false,
      images: [] as string[],
      imageIndex: 0,
    };
  },
  mounted() {
    const rootChangelogElement = document.getElementById(
      "changelog-items-container"
    );

    if (rootChangelogElement != null) {
      const imagesElements = rootChangelogElement.getElementsByTagName("img");

      for (let i = 0; i < imagesElements.length; i++) {
        this.images.push(imagesElements[i].src);
        imagesElements[i].onclick = () => {
          this.displayImagesLightBox = true;
          this.imageIndex = i;
        };
      }
    }
  },
  components: {
    ChangelogItem,
  },
  methods: {
    scrollToDiv(e: Event, divId: string) {
      e.preventDefault();

      const divElement = document.getElementById(divId);
      if (divElement) {
        divElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    showImagesLightBox() {
      this.displayImagesLightBox = true;
    },
    hideImagesLightBox() {
      this.displayImagesLightBox = false;
    },
  },
});
