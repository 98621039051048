<template>
  <div>
    <table class="table custom-style" v-if="displayTable">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Nazwa opcji</th>
          <th scope="col">Dozwolone wartości</th>
          <th scope="col">Opis opcji</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(option, i) of getData()" :key="i">
          <th scope="row">{{ i + 1 }}</th>
          <td>{{ option.name }}</td>
          <td>{{ option.possibleValues }}</td>
          <td v-html="option.description"></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import router from "../../router";

export default defineComponent({
  name: "NewSkalfiOptionsContent",
  props: ["options"],
  data() {
    return {};
  },
  computed: {
    displayTable() {
      if (
        this.$route.query.profileName &&
        this.$route.query.categoryName &&
        this.options
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    getData() {
      if (this.$route.query.profileName && this.$route.query.categoryName) {
        return JSON.parse(this.options);
      }
      return null;
    },
  },
});
</script>

<style scoped>
.custom-style {
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
  font-size: 16px;
}
</style>
