<template>
  <div class="instruction_container">
    <nav id="instruction_sidebar">
      <NewSkalfiOptionsMenu v-if="renderMenu" :dataSource="menuData" />
    </nav>

    <div id="instruction_content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import NewSkalfiOptionsMenu from "../components/NewSkalfiOptions/NewSkalfiOptionsMenu.vue";
import ApiService from "../services/apiService";

export default defineComponent({
  name: "NewSkalfiOptions",
  data() {
    return {
      loadingData: false,
      selectedProfileName: this.$route.params.profileName
        ? this.$route.params.profileName
        : null,
      selectedCategoryName: this.$route.params.categoryName
        ? this.$route.params.categoryName
        : null,
      categories: [],
      fetchedData: null,
      renderMenu: false,
    };
  },
  components: {
    NewSkalfiOptionsMenu,
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loadingData = true;
        const responseData = await ApiService.instance.getNewSkalfiOptionsData();
        this.fetchedData = responseData ? responseData.data : null;
        this.renderMenu = true;
      } catch (err) {
        console.log("ERROR: " + err);
      } finally {
        this.loadingData = false;
      }
    },
  },
  computed: {
    menuData() {
      const dataToRet = [];

      if (this.fetchedData) {
        const profilesNames = this.fetchedData.data.map(el => el.profileName);
        for (let i = 0; i < profilesNames.length; i++) {
          for (let j = 0; j < this.fetchedData.data.length; j++) {
            if (profilesNames[i] == this.fetchedData.data[j].profileName) {
              dataToRet.push({
                profileName: profilesNames[i],
                categories: this.fetchedData.data[j].data.map(el => ({
                  name: el.categoryName,
                  options: el.options,
                })),
              });
            }
          }
        }
      }
      return dataToRet;
    },
  },
});
</script>

<style scoped>
.instruction_container {
  display: flex;
  flex-direction: row;
}

#instruction_sidebar {
  min-width: 350px;
  height: 100vh;
  overflow: auto;
  background-color: #f7f7f7;
  margin: 0px;
}

#instruction_content {
  padding-left: 20px;
  width: 100%;
}
</style>
