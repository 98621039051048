
import { defineComponent, reactive } from "vue";
import Vue from "vue";
import $ from "jquery";

export default defineComponent({
  Name: "App",
  data() {
    return {};
  },
  mounted() {
    $(".dropdown-item").on("click", function() {
      ($(".navbar-collapse") as any).collapse("hide");
    });

    $('a[class="nav-link"]').on("click", function() {
      ($(".navbar-collapse") as any).collapse("hide");
    });
  },
  watch: {
    $route(to, from) {
      const tocButtonElement = document.getElementById("tocButton")!;

      if (to.fullPath.startsWith("/instructions")) {
        tocButtonElement.style.visibility = "visible";
      } else {
        tocButtonElement.style.visibility = "hidden";
      }
    },
  },
});
