
import { defineComponent } from "vue";

export default defineComponent({
  name: "ChangelogChangeItem",
  props: {
    type: Number,
    content: String,
  },
});
