
import { defineComponent } from "vue";
import ApiService from "../services/apiService";
import Loader from "../components/Loader.vue";
import { TechnicalSheetModel } from "../models/technicalSheetModel";

export default defineComponent({
  name: "Home",
  data() {
    return {
      loadingData: false,
      technicalSheetsData: [] as TechnicalSheetModel[],
    };
  },
  components: {
    Loader,
  },
  async created() {
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        this.loadingData = true;
        const responseData = await ApiService.instance.getTechnicalSheetsData();
        if (responseData !== undefined) {
          this.technicalSheetsData = responseData;
        }
      } catch (err) {
        console.log("ERROR: " + err);
      } finally {
        this.loadingData = false;
      }
    },
    downloadFile(e: Event, filePath: string) {
      e.preventDefault();
      window.location.href = filePath;
    },
  },
});
