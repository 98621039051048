export enum FaqType {
  RCPAdmin = "rcpadmin",
  KDAdmin = "kdadmin",
  AccessControl = "access_control",
  WebModule = "web_module",
  RODO = "rodo",
  EXPORT_IMPORT = "export_import",
  MiniStandardPremiumElite = "mini_standard_premium_elite",
  Usage = "usage",
}
