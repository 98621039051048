<template>
  <div>
    <h3>Wybierz profil:</h3>
    <CustomSelect
      :default="currentProfileName"
      :options="profilesNames"
      class="select"
      @selectionChanged="profileSelectionChanged"
    />

    <NewSkalfiOptionsMenuCategories
      :profile="selectedProfileName"
      :categories="categories"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import CustomSelect from "./CustomSelect.vue";
import NewSkalfiOptionsMenuCategories from "./NewSkalfiOptionsMenuCategories.vue";
import router from "../../router";

export default defineComponent({
  name: "NewSkalfiOptionsMenu",
  props: ["dataSource"],
  components: {
    CustomSelect,
    NewSkalfiOptionsMenuCategories,
  },
  data() {
    return {
      defaultCategory: null,
      categories: null,
      selectedProfileName: null,
    };
  },
  mounted() {
    if (this.$route.params.profileName && this.$route.params.categoryName) {
      this.updateCategories();
    }
  },
  computed: {
    currentProfileName() {
      return this.$route.query.profileName;
    },
    profilesNames() {
      let profilesToRet = [];

      if (this.dataSource) {
        profilesToRet = this.dataSource.map(el => el.profileName);
      }

      return profilesToRet;
    },
  },
  methods: {
    updateCategories() {
      this.categories = [];
      if (this.dataSource) {
        const currentProfile = this.dataSource.find(
          el => el.profileName == this.selectedProfileName
        );

        if (currentProfile) {
          this.categories = currentProfile.categories.map(el => ({
            name: el.name,
            options: el.options,
          }));
        }
      }
    },

    profileSelectionChanged(selectedItem) {
      if (
        selectedItem !== this.$route.query?.profileName &&
        this.$route.query?.profileName != undefined
      ) {
        this.$router.replace({ query: { profileName: selectedItem } });
      }

      this.selectedProfileName = selectedItem;
      this.updateCategories();

      if (this.$route.query?.categoryName) {
        const category = this.categories.find(
          el => el.name == this.$route.query?.categoryName
        );
        if (category) {
          router.push({
            name: "NewSkalfiOptionsContent",
            query: {
              profileName: this.selectedProfileName,
              categoryName: category.name,
            },
            params: {
              options: JSON.stringify(category.options),
            },
          });
        }
      }
    },
  },
});
</script>

<style scoped></style>
